import gql from 'graphql-tag'

const seo = gql`query GetSeoMetadata($url: String!) {
  sEO(where: {path: $url}) {
    seoMeta {
      pageTitle
      metaDescription
      canonical
      metaKeywords
      googleSchema
      metaRobots
      twitterImage {
        urls
      }
      openGraphImage {
        urls
      }
      defaultSocialImage {
        urls
      }
    }
  }
}`

export {
  seo,
}