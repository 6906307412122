<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top" id="navbar">
        <div class="container-lg h-100 nav-inner bg-white">
            <router-link class="navbar-brand logo-style h-100" to="/" @click="homeScroll()">
                <img src="@/assets/harbourlight_logo.svg" class="img-fluid me-2 me-md-0" />
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse bg-white ps-3" id="navbarNavDropdown">
                <ul class="navbar-nav ms-auto my-4 my-lg-0 text-start text-lg-center">
                    <li class="nav-item">
                        <router-link class="nav-link mx-3" to="/#homeAbout">Who we are</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link mx-3" to="/#homeServices">Services</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link mx-3" to="/#homeCaseStudies">Case Studies</router-link>
                    </li>
                    <li class="nav-item me-3">
                        <router-link class="nav-link mx-3 mb-2 mb-md-0" to="/#homeClients">Clients & Industries</router-link>
                    </li>
                    <li class="nav-item nav-button ms-2 ms-md-0">
                        <router-link class="nav-link mx-3" to="/#homeContact">Contact Us</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="nav-spacer" style="height: 150px;"></div>
</template>

<script setup lang="ts">
    import { scrollTop } from "@/utils/functions"
    
    function homeScroll () {
        if(window.scrollY == 0) {
            return
        }
        
        setTimeout(() => {
            scrollTop('')
        }, 50)
    }

    var cbpAnimatedHeader = (function() {

        var docElem = document.documentElement,
            header = document.querySelector( '.navbar' ),
            didScroll = false,
            changeHeaderOn = 10;

        function init() {
            window.addEventListener( 'scroll', function( event ) {
                if( !didScroll ) {
                    didScroll = true;
                    setTimeout( scrollPage, 1 );
                }
            }, false );
        }

        function scrollPage() {
            var element = document.getElementById("navbar");
            var sy = scrollY();
            if ( sy >= changeHeaderOn ) {
                element.classList.add("nav-header-shrink");
            }
            else {
                element.classList.remove("nav-header-shrink");
            }
            didScroll = false;
        }

        function scrollY() {
            return window.pageYOffset || docElem.scrollTop;
        }

        init();

        })();
    
</script>