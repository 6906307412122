<template>
    <footer class="footer mt-auto">
        <div class="bg-secondary d-flex justify-content-center">
            <div class="container d-flex justify-content-center">
                <div class="col-8 text-center my-5">
                    <router-link to="/" @click="homeScroll()"><img src="@/assets/harbourlight_logo-white.svg" class="img-fluid" style="max-width: 250px;"/></router-link>
                    <div class="row py-4">
                        <p class="text-center text-white px-3 pt-2 pb-4">Block C, Ground Floor, Essex Park, 46 Essex Terrace, Westville, 3629</p>
                        <ul class="nav flex-column flex-md-row justify-content-center">
                            <li class="px-2"><router-link to="/#homeAbout" class="text-white">Who We Are</router-link></li>
                            <li class="px-3 text-white d-none d-lg-block" style="opacity: .2;">|</li>
                            <li class="px-2"><router-link to="/#homeServices" class="text-white">Services</router-link></li>
                            <li class="px-3 text-white d-none d-lg-block" style="opacity: .2;">|</li>
                            <li class="px-2"><router-link to="/#homeCaseStudies" class="text-white">Case Studies</router-link></li>
                            <li class="px-3 text-white d-none d-lg-block" style="opacity: .2;">|</li>
                            <li class="px-2"><router-link to="/#homeClients" class="text-white">Clients & Industries</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-black px-3 py-4 py-md-0 d-flex justify-content-center">
            <div class="container row align-items-center">
                <div class="col-12 col-md-3 col-xl-2 d-flex justify-content-center">
                    <LottieObject class="footer-lighthouse d-flex align-items-end" jsonFile="footer-lighthouse.json"></LottieObject>
                </div>
                <div class="col-12 col-md-2 col-xl-3 py-3 py-md-0 d-flex justify-content-center">
                    <ul class="nav flex-column flex-md-row justify-content-center text-center align-items-center">
                        <li class=""><span class="text-white d-block" style="font-size: small;">&copy; {{ new Date().getFullYear() }} Harbourlight Insights</span></li>
                    </ul>
                </div>
                <div class="col-12 col-md-7 col-xl-7 d-flex justify-content-center justify-content-md-end">
                    <ul class="nav flex-row justify-content-center text-center align-items-center">
                        <!--<li class=""><router-link to="/paia" class="text-white d-block" style="font-size: small;">PAIA</router-link></li>-->
                        <!--<li class="pb-2 px-3 text-white" style="opacity: .2;">|</li>-->
                        <!--<li class=""><router-link to="/privacy-policy" class="text-white d-block" style="font-size: small;">Privacy Policy</router-link></li>-->
                        <!--<li class="pb-2 px-3 text-white d-none d-lg-block" style="opacity: .2;">|</li>-->
                        <li class="text-white d-block py-2 py-0 px-5 px-md-0" style="font-size: small; font-style: italic; line-height: 2.5;"><span class="pe-0 pe-md-3">Harbourlight is a division of</span><br class="d-block d-md-none"><a class="nologo-footer" href="https://www.nologostudios.com/" target="_blank"><span style="color: #00b5d1;">/</span><span style="color: #db3c31;">/</span><span style="color: #fbb533;">/</span> nologo studios</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
    import { CmsBaseUrl } from "@/utils/functions"
    import LottieObject from "@/components/general/LottieObject.vue"
    import { scrollTop } from "@/utils/functions"
    function homeScroll () {
        if(window.scrollY == 0) {
            return
        }
        
        setTimeout(() => {
            scrollTop('')
        }, 50)
    }
</script>