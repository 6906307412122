<template>
  <Navbar />
  <main>
    <router-view />
  </main>
  <Footer />
</template>

<script setup lang="ts">
  import { onMounted } from "vue"
  import { useHead } from "@unhead/vue"
  import Navbar from "@/components/navigation/Navbar.vue"
  import Footer from "@/components/navigation/Footer.vue"
  import router from "./router"
  import { setMetaData } from "@/utils/functions"
  import { useApolloClient } from '@vue/apollo-composable'

  const { resolveClient } = useApolloClient()
  const ApolloClient = resolveClient()

  useHead({
    titleTemplate: (titleChunk) => {
      return titleFormat(titleChunk);
    },
    meta: [{ name: "description", content: "" }],
  })

  onMounted(async () => {
    getMetaData(router.currentRoute.value.path)
  })

  router.afterEach((to, from) => {
    if (from.path !== to.path) {
      getMetaData(to.path)
    }
  })

  function getMetaData (path: string) {
    setMetaData(path == "/" ? "home" : path, ApolloClient)
  }

  function titleFormat (titleChunk: any) {
    const prependString = router.currentRoute.value.path == "/" ? "" : " | Harbourlight"
    return titleChunk ? titleChunk + prependString : "Harbourlight"
  }
</script>

<style lang="scss">
  @import "~bootstrap/scss/bootstrap.scss";
</style>
